import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Paragraph, Container, InnerContainer, Button, MainTitle, Info, InputField, ModelForm } from "app-premitives/index";
import useMerchantInfo from "app-core/theme/theme.hook";
import { setGiftdata, getTokenStatus, getMessage, getScreenInfo } from "../merchant.slice";
import { selectToken, selectMerchant, selectGiftData, selectDraftData, selectConfigs, selectScreenInfo } from "app-pages/merchant/merchant.selector";
import { Form } from "antd";
import backButton from 'app-assets/images/caret-left-solid.svg';
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

const Giftcode: React.FC = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(selectToken);
  const giftData = useSelector(selectGiftData);
  const merchant = useSelector(selectMerchant);
  const draftData = useSelector(selectDraftData);
  const config = useSelector(selectConfigs);
  const screenInfo = useSelector(selectScreenInfo);

  const [screen, setScreen] = useState(null) as any;
  const [isVisible, setIsVisible] = useState("invisible");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const giftCode = Form.useWatch("giftCode", form);
  const [model, setModel] = useState({
    giftCode: giftData?.giftCode ?? ""
  });
  const [statusChecked, setStatusChecked] = useState(false);
  const [navigateReady, setNavigateReady] = useState(false);

  const merchantId = merchant.merchantId;
  const action = 1;
  const isRedirect = screenInfo?.screenData?.find((screens: any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

  usePageTimeout();

  useEffect(() => {
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({ merchantId: merchant?.merchantId }));
    }
  }, [merchant, screenInfo.screenData, dispatch]);

  useEffect(() => {
    if (giftCode) onChange(giftCode);
  }, [giftCode]);

  useEffect(() => {
    if (giftCode === undefined || giftCode === "") {
      setIsVisible("invisible");
    } else {
      dispatch(getTokenStatus({ merchantId, giftCode, action }));      
    }
  }, [giftCode, dispatch, merchantId]);

  useEffect(() => {
    if (token.status_code === 200) {
      setIsVisible("visible");
      setError(token.message);
      if (token.result.status === 3) {
        dispatch(getMessage({ merchantId: merchant?.merchantId, giftCode: giftCode }));
      }
      // dispatch(setGiftdata({ giftCode }));
      // setNavigateReady(true);
    } else if (token.status_code === -99) {
      setIsVisible("visible");
      setError(token.message);
    } else {
      setIsVisible("visible");
      setError(token.message);
    }
  }, [token, dispatch, giftCode, merchant?.merchantId]);

  useEffect(() => {
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 2));
    }
  }, [screenInfo]);

  useEffect(() => {
    if (navigateReady) {
      navigate(`/${id}/senderdata`);
      setNavigateReady(false);
    }
  }, [navigateReady, navigate, id]);

  const onSubmit = async () => {
    if (draftData.result) {
      dispatch(setGiftdata({
        giftCode: giftCode,
        senderfname: draftData.result.sender.firstName,
        senderlname: draftData.result.sender.lastName,
        sendermail: draftData.result.sender.email,
        recieverfname: draftData.result.receiver.firstName,
        recieverlname: draftData.result.receiver.lastName,
        recievermail: draftData.result.receiver.email
      }));
      navigate(`/${id}/senderdata`);
    }

    if(token.status_code === 200){
      dispatch(setGiftdata({ giftCode }));
      setNavigateReady(true);
    }
    else if(token.status_code === -99){
      setNavigateReady(true);
    }
    else if(token.status_code === undefined){
      setNavigateReady(true);
    }
  };

  const onChange = (inputGiftCode: any) => {
    if (inputGiftCode.trim() === "" || inputGiftCode.trim() === undefined) {
      setIsVisible("invisible");
      return;
    }
    dispatch(getTokenStatus({ merchantId, giftCode: inputGiftCode, action }));
  };

  return (
    <Container>
      <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `../${id}` : '../'} bgColor={merchant?.backgroundColor} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          navigate("../");
          window.location.reload();
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div>
            <MainTitle align="center" size="3xl">
              {screen ? screen.data.title : ''}
            </MainTitle>
            <div className="mt-4 text-center">
              <Paragraph align="center">
                <div dangerouslySetInnerHTML={{ __html: screen?.data?.description }} />
              </Paragraph>
            </div>
            <div className="flex flex-col justify-center mt-4">
              <ModelForm model={model} form={form}>
                <Form.Item
                  label= {screen ? screen?.data?.giftCode : ''}
                  name="giftCode"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputField borderColor={merchant?.textboxFocusColor} hoverBorderColor={merchant?.textboxHoverColor} bgColor={merchant?.textboxBackgroundColor} hint="Enter gift code" />
                </Form.Item>
                <div className={`relative top-[-10px] text-blue-500 mx-4 text-[16px] ${isVisible} text-base`} >
                  {error}
                </div>
              </ModelForm>
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col justify-center">
              <Button
                type="base"
                btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor}
                fullwidth={true}
                onClick={onSubmit}
              >
                {screen ? screen?.data?.continueButton : ''}
              </Button>
              <div className="mt-4">
                <Info>
                  We will charge you just 89 cents for sending it, If you don’t have a gift code
                </Info>
              </div>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Giftcode;